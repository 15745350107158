@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


@layer base {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    @apply md:size-2 rounded-lg bg-gray-100;
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-slate-800/10 hover:bg-slate-800/30 rounded-lg;
  }
  html, body, #root {
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.1px;
    @apply h-screen leading-relaxed;
  }
  nav li a.active {
    @apply bg-gray-100;
  }
  #application-sidebar.open {
    @apply translate-x-0;
  }
}